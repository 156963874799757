<div
  [ngClass]="{
    'cursor-not-allowed': isDisabled
  }"
>
  <input
    [type]="type"
    [name]="name"
    required
    [placeholder]="placeholder"
    [class]="twClasses"
    [ngClass]="[
      inputClass ? inputClass : '',
      ngControl.control?.dirty &&
      ngControl.control?.touched &&
      ngControl.control?.invalid
        ? 'ring-red-500 focus:ring-red-500'
        : ''
    ]"
    autocomplete=""
    [autocomplete]="autocomplete"
    [ngModel]="value"
    (ngModelChange)="onChange($event)"
    (blur)="onBlur()"
    (focus)="onFocus()"
    [disabled]="isDisabled"
    (input)="onInput($event)"
    [value]="value"
  />
</div>

<!-- Handle input validations here -->
@if (ngControl.control?.dirty && ngControl.control?.touched) {
  <ng-container>
    <span
      *ngIf="ngControl.control?.hasError('email')"
      class="mt-2 text-sm text-red-600"
      >Invalid email address</span
    >
    <span
      *ngIf="ngControl.control?.hasError('required')"
      class="mt-2 text-sm text-red-600"
      >Field is required</span
    >

    <span
      *ngIf="ngControl.control?.hasError('pattern')"
      class="mt-2 text-sm text-red-600"
      >Password is not strong enough</span
    >

    <span
      *ngIf="ngControl.control?.hasError('minlength')"
      class="mt-2 text-sm text-red-600"
      >Field must be at least
      {{ ngControl.control?.errors?.['minlength']?.['requiredLength'] }}
      characters long</span
    >

    <span
      *ngIf="ngControl.control?.hasError('maxlength')"
      class="mt-2 text-sm text-red-600"
      >Field must be not be more than
      {{ ngControl.control?.errors?.['maxlength']?.['requiredLength'] }}
      characters long</span
    >

    <span
      *ngIf="ngControl.control?.hasError('phoneNumber')"
      class="mt-2 text-sm text-red-600"
      >Phone number is invalid</span
    >

    <span
      *ngIf="ngControl.control?.hasError('bvn')"
      class="mt-2 text-sm text-red-600"
      >BVN must be exactly 11 digits.</span
    >

    <span
      *ngIf="ngControl.control?.hasError('passwordMismatch')"
      class="mt-2 text-sm text-red-600"
      >Passwords do not match.</span
    >
  </ng-container>
}
